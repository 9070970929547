// @ts-ignore -- Unused import
import * as EntityClasses from "@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g";
import * as Entities from "@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g";
// @ts-ignore -- Unused import
import { jsonLightReadStringPropertyValue } from "@msdyn365-commerce/retail-proxy/dist/externals";

/**
 * rsmWineClubSignUpEntity entity interface.
 */
export interface IrsmWineClubSignUpEntity {
  WineClubId: string;
  CustomerId?: string;
  IsGifted?: boolean;
  DeliveryPreference?: string;
  AddressFirstName?: string;
  AddressLastName?: string;
  AddressEmail?: string;
  AddressPhone?: string;
  AddressLine1?: string;
  AddressLine2?: string;
  City?: string;
  State?: string;
  Zipcode?: string;
  Country?: string;
  ExtensionProperties?: Entities.CommerceProperty[];
}

/**
 * rsmClubMembershipPauseResumeResponse entity interface.
 */
export interface IrsmClubMembershipPauseResumeResponse {
  Status?: string;
  Result?: string;
}

/**
 * rsmClubMembershipSkipShipmentResponse entity interface.
 */
export interface IrsmClubMembershipSkipShipmentResponse {
  Status?: string;
  Result?: string;
}

/**
 * rsmClubMembershipPrimaryCardResponse entity interface.
 */
export interface IrsmClubMembershipPrimaryCardResponse {
  Status?: string;
  Result?: string;
}

/**
 * rsmClubMembershipPrimaryShipToAddressResponse entity interface.
 */
export interface IrsmClubMembershipPrimaryShipToAddressResponse {
  Status?: string;
  Result?: string;
}

/**
 * rsmClubMembershipUpdateResponse entity interface.
 */
export interface IrsmClubMembershipUpdateResponse {
  Status?: string;
  Result?: string;
}

/**
 * rsmClubMemberMgmtGetDataResponse entity interface.
 */
export interface IrsmClubMemberMgmtGetDataResponse {
  Status?: string;
  Result?: IWineClubMemberData[];
}

/**
 * rsmValidateProgramResponse entity interface.
 */
export interface IrsmValidateProgramResponse {
  Result?: boolean;
  Message?: string;
}

/**
 * rsmVerifyEmployeeResponse entity interface.
 */
export interface IrsmVerifyEmployeeResponse {
  Result?: boolean;
  Message?: string;
}

/**
 * rsmFriendsAndFamilyCode entity interface.
 */
export interface IrsmFriendsAndFamilyCode {
  RecipientEmailAddress?: string;
  Status?: string;
  Code?: string;
  SentDate?: string;
  ActivationDate?: string;
}

/**
 * rsmFriendsAndFamilyValidateProgramData entity interface.
 */
export interface IrsmFriendsAndFamilyValidateProgramData {
  Message?: string;
  Code?: IrsmFriendsAndFamilyCode;
}

/**
 * rsmFriendsAndFamilyValidateProgramResponse entity interface.
 */
export interface IrsmFriendsAndFamilyValidateProgramResponse {
  Status?: string;
  Result?: IrsmFriendsAndFamilyValidateProgramData;
}

/**
 * rsmFriendsAndFamilyListObject entity interface.
 */
export interface IrsmFriendsAndFamilyListObject {
  QuantityAvailable?: string;
  Codes?: IrsmFriendsAndFamilyCode[];
}

/**
 * rsmFriendsAndFamilyListResponse entity interface.
 */
export interface IrsmFriendsAndFamilyListResponse {
  Status?: string;
  Result?: IrsmFriendsAndFamilyListObject;
}

/**
 * rsmInsertMailingListResponse entity interface.
 */
export interface IrsmInsertMailingListResponse {
  IsSaved?: boolean;
  Message?: string;
}

/**
 * rsmUnsubscribeMailingListResponse entity interface.
 */
export interface IrsmUnsubscribeMailingListResponse {
  IsUnsubscribe?: boolean;
  Message?: string;
}

/**
 * rsmOfferSelectionsResponse entity interface.
 */
export interface IrsmOfferSelectionsResponse {
  result?: string;
}

/**
 * rsmSubscriptionLine entity interface.
 */
export interface IrsmSubscriptionLine {
  SubLineID?: string;
  Description?: string;
  Qty?: number;
}

/**
 * rsmSubscriptionData entity interface.
 */
export interface IrsmSubscriptionData {
  SubscriptionID?: string;
  SubStatus?: string;
  SubscriptionName?: string;
  Frequency?: string;
  NextOrderDate?: string;
  LockoutPeriod?: number;
  DeliveryAddress?: string;
  ActiveCreditCardID?: string;
  Lines?: IrsmSubscriptionLine[];
}

/**
 * rsmSubscriptionCancelResponse entity interface.
 */
export interface IrsmSubscriptionCancelResponse {
  Status?: string;
  Result?: IrsmSubscriptionData[];
}

/**
 * rsmSubscriptionPauseResponse entity interface.
 */
export interface IrsmSubscriptionPauseResponse {
  Status?: string;
  Result?: IrsmSubscriptionData[];
}

/**
 * rsmSubscriptionResumeResponse entity interface.
 */
export interface IrsmSubscriptionResumeResponse {
  Status?: string;
  Result?: IrsmSubscriptionData[];
}

/**
 * rsmSubscriptionUpdateResponse entity interface.
 */
export interface IrsmSubscriptionUpdateResponse {
  Status?: string;
  Result?: IrsmSubscriptionData[];
}

/**
 * rsmSubscriptionCard entity interface.
 */
export interface IrsmSubscriptionCard {
  CardID?: string;
  CardNumber?: string;
}

/**
 * rsmSubscriptionCardsResponse entity interface.
 */
export interface IrsmSubscriptionCardsResponse {
  Status?: string;
  Result?: IrsmSubscriptionCard[];
}

/**
 * rsmSubscriptionListResponse entity interface.
 */
export interface IrsmSubscriptionListResponse {
  Status?: string;
  Result?: IrsmSubscriptionData[];
}

/**
 * rsmWineClubSignUpResponse entity interface.
 */
export interface IrsmWineClubSignUpResponse {
  result?: boolean;
  Message?: string;
}

/**
 * CreditCard entity interface.
 */
export interface ICreditCard {
  RecId?: number;
  CreditCardNum?: string;
  IsPrimary?: boolean;
}

/**
 * ShipToAddress entity interface.
 */
export interface IShipToAddress {
  ShipToAddressRecId?: number;
  Name?: string;
  StreetAddress?: string;
  ZipCode?: string;
  City?: string;
  State?: string;
  DateBirth?: string;
  Company?: string;
  IsPrimary?: boolean;
}

/**
 * MembershipProducts entity interface.
 */
export interface IMembershipProducts {
  ItemId?: string;
  ItemName?: string;
  Qty?: number;
  OfferId?: string;
}

/**
 * OfferSelections entity interface.
 */
export interface IOfferSelections {
  OfferId?: string;
  OfferName?: string;
  EndDate?: Date;
  ItemId?: string;
}

/**
 * WineClubMemberData entity interface.
 */
export interface IWineClubMemberData {
  WineClubMemberNum?: string;
  WineClubId?: string;
  WineClubName?: string;
  WineClubDisplay?: string;
  NextClubRunDate?: string;
  CustomerName?: string;
  MemberStatus?: string;
  ShowStatus?: boolean;
  ActivationDate?: string;
  DeactivationDate?: string;
  GiftMembershipEndDate?: string;
  ShowMemberSince?: boolean;
  GiftCustName?: string;
  CanSkip?: boolean;
  IsBlackoutPeriod?: boolean;
  DeliveryPreference?: string;
  PickUpLocationId?: string;
  CreditCard?: ICreditCard[];
  ShipToAddress?: IShipToAddress[];
  MembershipProducts?: IMembershipProducts[];
  HasOfferSelections?: boolean;
  OfferSelections?: IOfferSelections[];
}

/**
 * ShipComplianceResponseObject entity interface.
 */
export interface IShipComplianceResponseObject {
  message?: string;
  isShipCompliant: boolean;
  callSucceeded: boolean;
}

/**
 * rsmWineClubSignUpEntity entity class.
 */
export class rsmWineClubSignUpEntityExtensionClass
  implements IrsmWineClubSignUpEntity {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public WineClubId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CustomerId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsGifted: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public DeliveryPreference: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AddressFirstName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AddressLastName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AddressEmail: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AddressPhone: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AddressLine1: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AddressLine2: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public City: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public State: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Zipcode: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Country: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: Entities.CommerceProperty[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.WineClubId = odataObject.WineClubId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CustomerId = odataObject.CustomerId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsGifted = odataObject.IsGifted;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.DeliveryPreference = odataObject.DeliveryPreference;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AddressFirstName = odataObject.AddressFirstName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AddressLastName = odataObject.AddressLastName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AddressEmail = odataObject.AddressEmail;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AddressPhone = odataObject.AddressPhone;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AddressLine1 = odataObject.AddressLine1;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AddressLine2 = odataObject.AddressLine2;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.City = odataObject.City;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.State = odataObject.State;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Zipcode = odataObject.Zipcode;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Country = odataObject.Country;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ExtensionProperties = undefined;
    if (odataObject.ExtensionProperties) {
      this.ExtensionProperties = [];
      for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[i]) {
          if (odataObject.ExtensionProperties[i]["@odata.type"]) {
            var className: string =
              odataObject.ExtensionProperties[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.ExtensionProperties[i] = new EntityClasses[className](
                odataObject.ExtensionProperties[i]
              );
            }
          } else {
            this.ExtensionProperties[
              i
            ] = new EntityClasses.CommercePropertyClass(
              odataObject.ExtensionProperties[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ExtensionProperties[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmClubMembershipPauseResumeResponse entity class.
 */
export class rsmClubMembershipPauseResumeResponseExtensionClass
  implements IrsmClubMembershipPauseResumeResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = odataObject.Result;
  }
}

/**
 * rsmClubMembershipSkipShipmentResponse entity class.
 */
export class rsmClubMembershipSkipShipmentResponseExtensionClass
  implements IrsmClubMembershipSkipShipmentResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = odataObject.Result;
  }
}

/**
 * rsmClubMembershipPrimaryCardResponse entity class.
 */
export class rsmClubMembershipPrimaryCardResponseExtensionClass
  implements IrsmClubMembershipPrimaryCardResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = odataObject.Result;
  }
}

/**
 * rsmClubMembershipPrimaryShipToAddressResponse entity class.
 */
export class rsmClubMembershipPrimaryShipToAddressResponseExtensionClass
  implements IrsmClubMembershipPrimaryShipToAddressResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = odataObject.Result;
  }
}

/**
 * rsmClubMembershipUpdateResponse entity class.
 */
export class rsmClubMembershipUpdateResponseExtensionClass
  implements IrsmClubMembershipUpdateResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = odataObject.Result;
  }
}

/**
 * rsmClubMemberMgmtGetDataResponse entity class.
 */
export class rsmClubMemberMgmtGetDataResponseExtensionClass
  implements IrsmClubMemberMgmtGetDataResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IWineClubMemberData[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = undefined;
    if (odataObject.Result) {
      this.Result = [];
      for (var i = 0; i < odataObject.Result.length; i++) {
        if (odataObject.Result[i]) {
          if (odataObject.Result[i]["@odata.type"]) {
            var className: string = odataObject.Result[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Result[i] = new EntityClasses[className](
                odataObject.Result[i]
              );
            }
          } else {
            this.Result[i] = new WineClubMemberDataExtensionClass(
              odataObject.Result[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Result[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmValidateProgramResponse entity class.
 */
export class rsmValidateProgramResponseExtensionClass
  implements IrsmValidateProgramResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = odataObject.Result;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;
  }
}

/**
 * rsmVerifyEmployeeResponse entity class.
 */
export class rsmVerifyEmployeeResponseExtensionClass
  implements IrsmVerifyEmployeeResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = odataObject.Result;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;
  }
}

/**
 * rsmFriendsAndFamilyCode entity class.
 */
export class rsmFriendsAndFamilyCodeExtensionClass
  implements IrsmFriendsAndFamilyCode {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public RecipientEmailAddress: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Code: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SentDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ActivationDate: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.RecipientEmailAddress = odataObject.RecipientEmailAddress;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Code = odataObject.Code;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SentDate = odataObject.SentDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ActivationDate = odataObject.ActivationDate;
  }
}

/**
 * rsmFriendsAndFamilyValidateProgramData entity class.
 */
export class rsmFriendsAndFamilyValidateProgramDataExtensionClass
  implements IrsmFriendsAndFamilyValidateProgramData {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Code: IrsmFriendsAndFamilyCode;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;

    if (odataObject.Code === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Code = undefined;
    } else if (odataObject.Code["@odata.type"] == null) {
      this.Code = new rsmFriendsAndFamilyCodeExtensionClass(odataObject.Code);
    } else {
      var className: string = odataObject.Code["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Code = new EntityClasses[className](odataObject.Code);
      }
    }
  }
}

/**
 * rsmFriendsAndFamilyValidateProgramResponse entity class.
 */
export class rsmFriendsAndFamilyValidateProgramResponseExtensionClass
  implements IrsmFriendsAndFamilyValidateProgramResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IrsmFriendsAndFamilyValidateProgramData;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    if (odataObject.Result === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Result = undefined;
    } else if (odataObject.Result["@odata.type"] == null) {
      this.Result = new rsmFriendsAndFamilyValidateProgramDataExtensionClass(
        odataObject.Result
      );
    } else {
      var className: string = odataObject.Result["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Result = new EntityClasses[className](odataObject.Result);
      }
    }
  }
}

/**
 * rsmFriendsAndFamilyListObject entity class.
 */
export class rsmFriendsAndFamilyListObjectExtensionClass
  implements IrsmFriendsAndFamilyListObject {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public QuantityAvailable: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Codes: IrsmFriendsAndFamilyCode[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.QuantityAvailable = odataObject.QuantityAvailable;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Codes = undefined;
    if (odataObject.Codes) {
      this.Codes = [];
      for (var i = 0; i < odataObject.Codes.length; i++) {
        if (odataObject.Codes[i]) {
          if (odataObject.Codes[i]["@odata.type"]) {
            var className: string = odataObject.Codes[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Codes[i] = new EntityClasses[className](
                odataObject.Codes[i]
              );
            }
          } else {
            this.Codes[i] = new rsmFriendsAndFamilyCodeExtensionClass(
              odataObject.Codes[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Codes[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmFriendsAndFamilyListResponse entity class.
 */
export class rsmFriendsAndFamilyListResponseExtensionClass
  implements IrsmFriendsAndFamilyListResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IrsmFriendsAndFamilyListObject;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    if (odataObject.Result === null) {
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Result = undefined;
    } else if (odataObject.Result["@odata.type"] == null) {
      this.Result = new rsmFriendsAndFamilyListObjectExtensionClass(
        odataObject.Result
      );
    } else {
      var className: string = odataObject.Result["@odata.type"];
      className = className
        .substr(className.lastIndexOf(".") + 1)
        .concat("Class");
      if (EntityClasses[className]) {
        this.Result = new EntityClasses[className](odataObject.Result);
      }
    }
  }
}

/**
 * rsmInsertMailingListResponse entity class.
 */
export class rsmInsertMailingListResponseExtensionClass
  implements IrsmInsertMailingListResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsSaved: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsSaved = odataObject.IsSaved;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;
  }
}

/**
 * rsmUnsubscribeMailingListResponse entity class.
 */
export class rsmUnsubscribeMailingListResponseExtensionClass
  implements IrsmUnsubscribeMailingListResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsUnsubscribe: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsUnsubscribe = odataObject.IsUnsubscribe;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;
  }
}

/**
 * rsmOfferSelectionsResponse entity class.
 */
export class rsmOfferSelectionsResponseExtensionClass
  implements IrsmOfferSelectionsResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public result: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.result = odataObject.result;
  }
}

/**
 * rsmSubscriptionLine entity class.
 */
export class rsmSubscriptionLineExtensionClass implements IrsmSubscriptionLine {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SubLineID: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Description: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Qty: number;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SubLineID = odataObject.SubLineID;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Description = odataObject.Description;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Qty = odataObject.Qty;
  }
}

/**
 * rsmSubscriptionData entity class.
 */
export class rsmSubscriptionDataExtensionClass implements IrsmSubscriptionData {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SubscriptionID: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SubStatus: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public SubscriptionName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Frequency: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public NextOrderDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public LockoutPeriod: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public DeliveryAddress: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ActiveCreditCardID: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Lines: IrsmSubscriptionLine[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SubscriptionID = odataObject.SubscriptionID;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SubStatus = odataObject.SubStatus;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.SubscriptionName = odataObject.SubscriptionName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Frequency = odataObject.Frequency;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.NextOrderDate = odataObject.NextOrderDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.LockoutPeriod = odataObject.LockoutPeriod;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.DeliveryAddress = odataObject.DeliveryAddress;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ActiveCreditCardID = odataObject.ActiveCreditCardID;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Lines = undefined;
    if (odataObject.Lines) {
      this.Lines = [];
      for (var i = 0; i < odataObject.Lines.length; i++) {
        if (odataObject.Lines[i]) {
          if (odataObject.Lines[i]["@odata.type"]) {
            var className: string = odataObject.Lines[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Lines[i] = new EntityClasses[className](
                odataObject.Lines[i]
              );
            }
          } else {
            this.Lines[i] = new rsmSubscriptionLineExtensionClass(
              odataObject.Lines[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Lines[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmSubscriptionCancelResponse entity class.
 */
export class rsmSubscriptionCancelResponseExtensionClass
  implements IrsmSubscriptionCancelResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IrsmSubscriptionData[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = undefined;
    if (odataObject.Result) {
      this.Result = [];
      for (var i = 0; i < odataObject.Result.length; i++) {
        if (odataObject.Result[i]) {
          if (odataObject.Result[i]["@odata.type"]) {
            var className: string = odataObject.Result[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Result[i] = new EntityClasses[className](
                odataObject.Result[i]
              );
            }
          } else {
            this.Result[i] = new rsmSubscriptionDataExtensionClass(
              odataObject.Result[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Result[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmSubscriptionPauseResponse entity class.
 */
export class rsmSubscriptionPauseResponseExtensionClass
  implements IrsmSubscriptionPauseResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IrsmSubscriptionData[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = undefined;
    if (odataObject.Result) {
      this.Result = [];
      for (var i = 0; i < odataObject.Result.length; i++) {
        if (odataObject.Result[i]) {
          if (odataObject.Result[i]["@odata.type"]) {
            var className: string = odataObject.Result[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Result[i] = new EntityClasses[className](
                odataObject.Result[i]
              );
            }
          } else {
            this.Result[i] = new rsmSubscriptionDataExtensionClass(
              odataObject.Result[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Result[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmSubscriptionResumeResponse entity class.
 */
export class rsmSubscriptionResumeResponseExtensionClass
  implements IrsmSubscriptionResumeResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IrsmSubscriptionData[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = undefined;
    if (odataObject.Result) {
      this.Result = [];
      for (var i = 0; i < odataObject.Result.length; i++) {
        if (odataObject.Result[i]) {
          if (odataObject.Result[i]["@odata.type"]) {
            var className: string = odataObject.Result[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Result[i] = new EntityClasses[className](
                odataObject.Result[i]
              );
            }
          } else {
            this.Result[i] = new rsmSubscriptionDataExtensionClass(
              odataObject.Result[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Result[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmSubscriptionUpdateResponse entity class.
 */
export class rsmSubscriptionUpdateResponseExtensionClass
  implements IrsmSubscriptionUpdateResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IrsmSubscriptionData[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = undefined;
    if (odataObject.Result) {
      this.Result = [];
      for (var i = 0; i < odataObject.Result.length; i++) {
        if (odataObject.Result[i]) {
          if (odataObject.Result[i]["@odata.type"]) {
            var className: string = odataObject.Result[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Result[i] = new EntityClasses[className](
                odataObject.Result[i]
              );
            }
          } else {
            this.Result[i] = new rsmSubscriptionDataExtensionClass(
              odataObject.Result[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Result[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmSubscriptionCard entity class.
 */
export class rsmSubscriptionCardExtensionClass implements IrsmSubscriptionCard {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CardID: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CardNumber: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CardID = odataObject.CardID;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CardNumber = odataObject.CardNumber;
  }
}

/**
 * rsmSubscriptionCardsResponse entity class.
 */
export class rsmSubscriptionCardsResponseExtensionClass
  implements IrsmSubscriptionCardsResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IrsmSubscriptionCard[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = undefined;
    if (odataObject.Result) {
      this.Result = [];
      for (var i = 0; i < odataObject.Result.length; i++) {
        if (odataObject.Result[i]) {
          if (odataObject.Result[i]["@odata.type"]) {
            var className: string = odataObject.Result[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Result[i] = new EntityClasses[className](
                odataObject.Result[i]
              );
            }
          } else {
            this.Result[i] = new rsmSubscriptionCardExtensionClass(
              odataObject.Result[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Result[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmSubscriptionListResponse entity class.
 */
export class rsmSubscriptionListResponseExtensionClass
  implements IrsmSubscriptionListResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Status: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Result: IrsmSubscriptionData[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Status = odataObject.Status;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Result = undefined;
    if (odataObject.Result) {
      this.Result = [];
      for (var i = 0; i < odataObject.Result.length; i++) {
        if (odataObject.Result[i]) {
          if (odataObject.Result[i]["@odata.type"]) {
            var className: string = odataObject.Result[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.Result[i] = new EntityClasses[className](
                odataObject.Result[i]
              );
            }
          } else {
            this.Result[i] = new rsmSubscriptionDataExtensionClass(
              odataObject.Result[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Result[i] = undefined;
        }
      }
    }
  }
}

/**
 * rsmWineClubSignUpResponse entity class.
 */
export class rsmWineClubSignUpResponseExtensionClass
  implements IrsmWineClubSignUpResponse {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public result: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Message: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.result = odataObject.result;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Message = odataObject.Message;
  }
}

/**
 * CreditCard entity class.
 */
export class CreditCardExtensionClass implements ICreditCard {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public RecId: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CreditCardNum: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsPrimary: boolean;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.RecId = odataObject.RecId ? parseInt(odataObject.RecId, 10) : 0;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CreditCardNum = odataObject.CreditCardNum;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsPrimary = odataObject.IsPrimary;
  }
}

/**
 * ShipToAddress entity class.
 */
export class ShipToAddressExtensionClass implements IShipToAddress {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ShipToAddressRecId: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Name: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public StreetAddress: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ZipCode: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public City: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public State: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public DateBirth: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Company: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsPrimary: boolean;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ShipToAddressRecId = odataObject.ShipToAddressRecId
      ? parseInt(odataObject.ShipToAddressRecId, 10)
      : 0;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Name = odataObject.Name;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.StreetAddress = odataObject.StreetAddress;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ZipCode = odataObject.ZipCode;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.City = odataObject.City;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.State = odataObject.State;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.DateBirth = odataObject.DateBirth;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Company = odataObject.Company;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsPrimary = odataObject.IsPrimary;
  }
}

/**
 * MembershipProducts entity class.
 */
export class MembershipProductsExtensionClass implements IMembershipProducts {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Qty: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public OfferId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ItemId = odataObject.ItemId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ItemName = odataObject.ItemName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Qty = odataObject.Qty;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.OfferId = odataObject.OfferId;
  }
}

/**
 * OfferSelections entity class.
 */
export class OfferSelectionsExtensionClass implements IOfferSelections {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public OfferId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public OfferName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public EndDate: Date;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.OfferId = odataObject.OfferId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.OfferName = odataObject.OfferName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.EndDate =
      odataObject.EndDate instanceof Date
        ? odataObject.EndDate
        : odataObject.EndDate
        ? jsonLightReadStringPropertyValue(
            odataObject.EndDate,
            "Edm.DateTimeOffset",
            false
          )
        : undefined;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ItemId = odataObject.ItemId;
  }
}

/**
 * WineClubMemberData entity class.
 */
export class WineClubMemberDataExtensionClass implements IWineClubMemberData {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public WineClubMemberNum: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public WineClubId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public WineClubName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public WineClubDisplay: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public NextClubRunDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CustomerName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public MemberStatus: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ShowStatus: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ActivationDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public DeactivationDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public GiftMembershipEndDate: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ShowMemberSince: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public GiftCustName: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CanSkip: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public IsBlackoutPeriod: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public DeliveryPreference: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public PickUpLocationId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CreditCard: ICreditCard[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ShipToAddress: IShipToAddress[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public MembershipProducts: IMembershipProducts[];

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public HasOfferSelections: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public OfferSelections: IOfferSelections[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.WineClubMemberNum = odataObject.WineClubMemberNum;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.WineClubId = odataObject.WineClubId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.WineClubName = odataObject.WineClubName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.WineClubDisplay = odataObject.WineClubDisplay;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.NextClubRunDate = odataObject.NextClubRunDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CustomerName = odataObject.CustomerName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.MemberStatus = odataObject.MemberStatus;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ShowStatus = odataObject.ShowStatus;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ActivationDate = odataObject.ActivationDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.DeactivationDate = odataObject.DeactivationDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.GiftMembershipEndDate = odataObject.GiftMembershipEndDate;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ShowMemberSince = odataObject.ShowMemberSince;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.GiftCustName = odataObject.GiftCustName;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CanSkip = odataObject.CanSkip;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.IsBlackoutPeriod = odataObject.IsBlackoutPeriod;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.DeliveryPreference = odataObject.DeliveryPreference;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.PickUpLocationId = odataObject.PickUpLocationId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.CreditCard = undefined;
    if (odataObject.CreditCard) {
      this.CreditCard = [];
      for (var i = 0; i < odataObject.CreditCard.length; i++) {
        if (odataObject.CreditCard[i]) {
          if (odataObject.CreditCard[i]["@odata.type"]) {
            var className: string = odataObject.CreditCard[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.CreditCard[i] = new EntityClasses[className](
                odataObject.CreditCard[i]
              );
            }
          } else {
            this.CreditCard[i] = new CreditCardExtensionClass(
              odataObject.CreditCard[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.CreditCard[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ShipToAddress = undefined;
    if (odataObject.ShipToAddress) {
      this.ShipToAddress = [];
      for (var i = 0; i < odataObject.ShipToAddress.length; i++) {
        if (odataObject.ShipToAddress[i]) {
          if (odataObject.ShipToAddress[i]["@odata.type"]) {
            var className: string = odataObject.ShipToAddress[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.ShipToAddress[i] = new EntityClasses[className](
                odataObject.ShipToAddress[i]
              );
            }
          } else {
            this.ShipToAddress[i] = new ShipToAddressExtensionClass(
              odataObject.ShipToAddress[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ShipToAddress[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.MembershipProducts = undefined;
    if (odataObject.MembershipProducts) {
      this.MembershipProducts = [];
      for (var i = 0; i < odataObject.MembershipProducts.length; i++) {
        if (odataObject.MembershipProducts[i]) {
          if (odataObject.MembershipProducts[i]["@odata.type"]) {
            var className: string =
              odataObject.MembershipProducts[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.MembershipProducts[i] = new EntityClasses[className](
                odataObject.MembershipProducts[i]
              );
            }
          } else {
            this.MembershipProducts[i] = new MembershipProductsExtensionClass(
              odataObject.MembershipProducts[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.MembershipProducts[i] = undefined;
        }
      }
    }

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.HasOfferSelections = odataObject.HasOfferSelections;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.OfferSelections = undefined;
    if (odataObject.OfferSelections) {
      this.OfferSelections = [];
      for (var i = 0; i < odataObject.OfferSelections.length; i++) {
        if (odataObject.OfferSelections[i]) {
          if (odataObject.OfferSelections[i]["@odata.type"]) {
            var className: string =
              odataObject.OfferSelections[i]["@odata.type"];
            className = className
              .substr(className.lastIndexOf(".") + 1)
              .concat("Class");
            if (EntityClasses[className]) {
              this.OfferSelections[i] = new EntityClasses[className](
                odataObject.OfferSelections[i]
              );
            }
          } else {
            this.OfferSelections[i] = new OfferSelectionsExtensionClass(
              odataObject.OfferSelections[i]
            );
          }
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.OfferSelections[i] = undefined;
        }
      }
    }
  }
}

/**
 * ShipComplianceResponseObject entity class.
 */
export class ShipComplianceResponseObjectExtensionClass
  implements IShipComplianceResponseObject {
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public message: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public isShipCompliant: boolean;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public callSucceeded: boolean;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.message = odataObject.message;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.isShipCompliant = odataObject.isShipCompliant;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.callSucceeded = odataObject.callSucceeded;
  }
}
